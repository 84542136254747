
import { FILE_UPLOAD, SAMPLE_GROUP_SIZE_MAX, MAX_CYCLE_SIZE, PO_MAXLENGTH, DROP_ZONE_CONSTANTS } from "./dataFormat";

export const NO_ROLES_ASSIGNED = "No role(s) assigned, Please contact Admin";

export const VENDOR_SAVE_SUCCESS =
  "Vendor has been saved successfully";
export const VENDOR_UPDATE_SUCCESS =
  "Vendor has been updated successfully";
export const VENDOR_MERGE_SUCCESS = "Vendor(s) has been merged successfully";

export const VENDOR_FILE_UPLOADED = "Vendor has been uploaded successfully";

export const VENDOR_NOT_EMPTY = "Vendor cannot be empty";
export const MAXIMUM_VENDORS_MERGE_WARNING = "System won't allow to merge more than 50 vendors. Please reduce the vendors";
export const MERGE_TO_INACTIVE_VENDOR = "Vendors should not merge to Inactive Vendor";
export const SAMPLE_TYPE_NOT_EMPTY = "Sample type cannot be empty";
export const MASTER_VENDOR_MERGE_TO_MANUAL = "Can't merge master vendor to a manually added vendor.";
export const MASTER_VENDOR_MERGE = "Can't merge between master vendors.";
export const USER_NAME_NOT_EMPTY="User name cannot be empty";

export const SAMPLE_TYPE_SAVE_SUCCESS =
  "Sample type has been saved successfully";
export const SAMPLE_TYPE_UPDATE_SUCCESS =
  "Sample type has been updated successfully";

export const SAMPLE_TYPE_ALPHABET_ONLY = "Sample type should contain letter's and number";
export const REPORT_FORMAT_NOT_EMPTY = "Report Format cannot be empty";
export const TEST_TYPE_NOT_EMPTY = "Test type cannot be empty";
export const TEST_CYCLE_OPTION_NOT_EMPTY = "Test cycle Options cannot be empty";
export const TEST_TYPE_LETTER_NUMBER_SYMBOL_ONLY =
  "Allowed special symbols '!','@','#','$','%','^','&','*'";
export const TEST_TYPE_SAVE_SUCCESS = "Test type has been saved successfully";
export const TEST_TYPE_UPDATE_SUCCESS =
  "Test type has been updated successfully";
export const SAMPLE_TYPE_AND_HEADER_MAPPED_SUCCESS =
  "Sample type and header(s) are mapped successfully";
export const SAMPLE_TYPE_AND_VENDOR_MAPPED_SUCCESS =
  "Sample type and vendor(s) are mapped successfully";
export const SAMPLE_TYPE_AND_VENDOR_MAPPED_FAILURE = "Atleast one vendor should be mapped";
export const QUESTION_OPTION_UNIQUE = "Question option should be unique";
export const QUESTION_OPTION_DECIMAL_ERROR =
  "Question Option cannot have more than 2 decimal digits";
export const QUESTION_OPTION_TEXT =
  "Allowed special symbols '!','@','#','$','%','^','&','*'";
export const QUESTION_OPTION_NUMBER_ONLY =
  "Options should contain number only";
export const QUESTION_NOT_EMPTY = "Question cannot be empty";
export const QUESTION_ALREADY_EXIST = "Question already exist";
export const QUESTION_BULK_UPLOAD_SAVED = "Questions has been uploaded successfully";
export const QUESTION_CONSTRAINTS_BULK_UPLOAD_SAVED = "Question Constraints has been uploaded successfully";
export const UPLOAD_FILE_ERRORS = "Uploaded file has errors. Please check the downloaded file";

export const LAB_ALREADY_EXIST = "Lab already exist";
export const HEADER_FIELD_OPTION_UNIQUE = "Header option should be unique";
export const HEADER_FIELD_OPTION_TEXT =
  "Header  option should contain string and special symbol only";
export const HEADER_FIELD_OPTION_NUMBER_ONLY =
  "Header  option should contain number only";
export const HEADER_FIELD_NOT_EMPTY = "Header cannot be empty";
export const UNIT_TYPE_NOT_EMPTY = "Unit type cannot be empty";
export const VALUE_TYPE__NOT_EMPTY = "Value type cannot be empty";
export const PREDEFINED_DATASET_NOT_EMPTY =
  "Predefined dataset cannot be empty";
export const MIN_VALUE_NOT_EMPTY = "Min value cannot be empty";
export const MAX_VALUE__NOT_EMPTY = "Max value cannot be empty";
export const DECIMAL_DIGITS_RANGE_FROM_1_5 = "Decimal digits range should be between 1-5";
export const DATA_TYPE_NOT_EMPTY = "Data type cannot be empty";
export const HEADER_FIELD_ALREADY_EXIST = "Header already exist";
export const SAMPLE_TYPE_ALREADY_EXIST = "Sample type already exist";
export const TEST_TYPE_ALREADY_EXIST = "Test type already exist";
export const MAX_QUESTION_FAILURE_LESS_THAN_MAPPED_QUESTION = "Max allowed question failures should be less than selected questions count(s)";
export const HEADER_FIELD_OPTION_NOT_EMPTY = "Header option(s) cannot be empty";
export const HEADER_FIELD_OPTION_SAME_DATA_SELECTION =
  "Header option should be same with data type selection";
export const QUESION_SAVED_SUCCESS = "Question has been saved successfully";
export const QUESION_UPDATED_SUCCESS = "Question has been updated successfully";
export const QUESTION_OPTION_NOT_EMPTY = "Question option(s) cannot be empty";
export const QUESTION_OPTION_SAME_DATA_SELECTION =
  "Question option should be same with data type selection";
export const QUESTION_MORE_DETAILS_INVALID_URL =
  "URL given in the additional info field is invalid";
export const HEADER_FIELD_SAVED_SUCCESS = "Header has been saved successfully";
export const HEADER_FIELD_UPDATED_SUCCESS =
  "Header has been updated successfully";

export const LAB_EQUIPMENT_ALREADY_EXISTS = "Lab Equipment is already exits";
export const LAB_EQUIPMENT_SAVE_SUCCESS = "Lab Equipment has been saved successfully";
export const LAB_EQUIPMENT_SERAL_NOT_EMPTY = " Eqiupment name, lab name, serial number cannot be empty!";
export const LAB_EQUIPMENT_UPDATE_SUCCESS = "Lab Equipment has been updated successfully";
export const CALIBRATED_ON_DATE_SHOULD_BE_LESS_THAN_DUE_ON_DATE = "Last Calibrated On date should be less than Calibration Due On date";
export const CALIBRATED_DUE_ON_DATE_SHOULD_BE_GREATER_THAN_ON_DATE = "Calibrated Due On date should be greater than Last Calibration On date";

export const EQUIPMENT_SAVE_SUCCESS = "Equipment has been saved successfully";
export const EQUIPMENT__UPDATE_SUCCESS = "Equipment has been updated successfully";


export const SKU_NOT_EMPTY = "SKU cannot be empty";
export const SKU_LETTER_NUMBER_SYMBOL_ONLY =
  "SKU should contain letter, number and symbols";
export const SKU_SAVE_SUCCESS = "SKU has been saved successfully";
export const ATRC_SAVE_SUCCESS = "Auto Test Request Mappings has been saved successfully";
export const SKU_UPDATE_SUCCESS = "SKU has been updated successfully";
export const ATRC_UPDATE_SUCCESS = "Auto Test Request Mappings has been updated successfully";
export const ENTER_SKU_NAME = "Enter a SKU name.";
export const SELECT_VALID_LAB_NAME = "Select a valid lab name";
export const SELECT_VALID_ON_BEHALF_OF_USERS = "Select a on behalf of user";
export const SELECT_VALID_SAMPLE_TYPE = "Select valid sample type.";
export const ENTER_SKU_DESCRIPTION = "Enter a SKU description";
export const EMPTY_SKU_MAPPING =
  "There are no sku's associated with this sample type.";
export const INACTIVE_SKU_MAPPING =
  "There are no active sku's associated with this sample type.";
export const LAB_NOT_EMPTY = "Lab cannot be empty";
export const LAB_LETTER_NUMBER_SYMBOL_ONLY =
  "Lab should contain letter,number and symbols";
export const LAB_SAVE_SUCCESS = "Lab has been saved successfully";
export const LAB_UPDATE_SUCCESS = "Lab has been updated successfully";
export const SITE_NOT_EMPTY = "Site cannot be empty";
export const LAB_SITE_NOT_EMPTY = "Lab and Site cannot be empty";
export const LAB_MASTER_NOT_EMPTY = "Lab Master Id cannot be empty";

export const SELECT_VALID_QUESTION = "Select valid question.";
export const SELECT_VALID_SKU = "Select valid SKU.";
export const SELECT_VALID_TEST_TYPE = "Select valid Test Type.";
export const QUESTION_CONSTRAINT_SAVED =
  "Question constraint saved successfully.";
export const QUESTION_CONSTRAINT_UPDATE =
  "Question constraint updated successfully.";
export const QUESTION_CONSTRAINT_DELETED =
  "Selected question constraint successfully deleted.";
export const QUESTION_CONSTRAINT_DELETE_CONFIRM_TITLE =
  "Delete Question Constraint";
export const QUESTION_CONSTRAINT_DELETE_CONFIRM_MESSAGE =
  "Are you sure you want to delete the selected constraint?";
export const MIN_DATE_SHOULD_BE_LESS_THAN =
  "Min date should be less than max date";
export const MAX_DATE_SHOULD_BE_GREATER_THAN =
  "Max date should be greater than min date";
export const MIN_VALUE_SHOULD_BE_LESS_THAN =
  "Min value should be less than max value";
export const MAX_SHOULD_GREATER_MIN =
  "Max value should be greater than Min value.";
export const NOMINAL_SHOULD_BE_IN_MINMAX_RANGE =
  "Nominal value should be with in Min Max Range.";
export const SAMPLE_GROUP_TEST_TYPE_MAPPED_SUCCESS =
  "Tests are mapped successfully to the Sample Group.";
export const SAMPLE_GROUP_TEST_TYPE_MAPPED_ERROR = "Should have at least 1 mapped test";
export const SAMPLE_GROUP_CREATED = "Sample group created successfully.";
export const SAMPLE_GROUP_UPDATED = "Sample group updated successfully.";
export const SAMPLE_GROUP_INVALID_SELECT = "Select a valid value.";
export const SAMPLE_GROUP_INVALID_INPUT = "Enter a valid value.";
export const SAMPLE_GROUP_SAMPLE_SIZE_DECREASED = "Sample size cannot be decreased";
export const SAMPLE_TYPE_TEST_TYPE_MAPPING_MISSING =
  "There are no tests currently mapped to the selected sample type";

export const QUESTION_FORMULA_VALIDATED = "Question formula validated successfully.";
export const QUESTION_FORMULA_SAVED = "Question formula successfully saved.";
export const QUESTION_FORMULA_REMOVED = "Question formula successfully deleted.";
export const CUSTOM_UNIT_TYPE_NOT_EMPTY = "Custom unit type cannot be empty.";
export const CUSTOM_UNIT_TYPE_EXIST = "Custom unit type already exist.";
export const CUSTOM_UNIT_TYPE_SIZE = "Custom unit type cannot have more than 20 characters.";

export const SAMPLE_TEST_RESULT_UPDATED = "Sample Test result updated.";
export const SAMPLE_TEST_STATUS_UPDATED = "Sample Test status updated.";
export const SAMPLE_TEST_STATUS_SKIPPED = "Test status marked as skipped successfully.";
export const CHECK_SAMPLE_TEST_TYPES = "Please select the Sample Test Types.";
export const CHECK_SAMPLES = "Please select the Samples.";
export const CHECK_SAMPLE_GROUP = "Please select the Sample Group.";
export const CHECK_TEST_REQUEST = "Please select the Test Request.";

export const SAMPLE_GROUP_RESULT_UPDATED = "Sample Group result updated.";
export const SAMPLE_GROUP_RESULT_FAILED = "Sample Group result update failed.";
export const SAMPLE_GROUP_STATUS_UPDATED = "Sample Group status updated.";
export const SAMPLE_GROUP_STATUS_FAILED = "Sample Group status update failed.";
export const SAMPLE_GROUP_DELETED = "Sample Group deleted successfully.";
export const TEST_REQUEST_DELETED = "Test Request deleted successfully.";
export const SAMPLE_RESULT_UPDATED = "Sample result updated.";
export const SAMPLE_RESULT_FAILED = "Sample result update failed.";
export const SAMPLE_STATUS_UPDATED = "Sample status updated.";
export const SAMPLE_STATUS_FAILED = "Sample status update failed.";
export const SAMPLE_STATUS_UPDATED_SKIPPED = "Selected samples marked as skipped successfully.";
export const SAMPLE_TEST_CYCLE_SAVE_SUCCESS = "Sample Test Cycles are saved successfully";
export const SAMPLE_TEST_CYCLE_EMPTY_VALUE = "Sample Test Cycle cannot be empty";
export const TESTCYCLE_OPTION_UNIQUE = "Test cycle must be unique";
export const TEST_DATA_SAVED_SUCCESS = "Test data saved successfully.";
export const TEST_DATA_RESULT_SAVED_SUCCESS = "Test data saved and result calculated successfully.";
export const TEST_RESULT_OVERRIDE_SAVED_SUCCESS = "Test result saved and overrided all validations successfully.";
export const MODIFICATION_USER_CONFIRMATION_MESSAGE = "You have unsaved changes. Are you sure you want to leave?";
export const HEADER_VALUE_ERROR = "Value should be between [minValue] and [maxValue]";

export const HEADER_POMAXLENGTH_ERROR = "Value should not have more than " + PO_MAXLENGTH + " characters";

export const NOT_VALID_VENDOR_OPTION = "Select a valid vendor from the options";
export const UNMAPPED_VENDOR_SAMPLE_TYPE = "Note: Vendor is not mapped with selected sample type";
export const HEADER_MIN_VALUE_ERROR =
  "Value should be greater than [minValue]";
export const HEADER_MAX_VALUE_ERROR =
  "Value should be less than [maxValue]";
export const HEADER_DECIMALNOTALLOWED_ERROR =
  "Value cannot have any digits in decimal position";
export const HEADER_DECIMAL_ERROR =
  "Value cannot have more than {0} decimal digits";
export const HEADER_OPTION_DECIMAL_ERROR =
  "Header Option cannot have more than 2 decimal digits";
export const EMPTY_ERROR = "Value cannot be empty";
export const SAMPLE_GROUP_SUBMIT_ERROR = "Please enter valid values";
export const VENDOR_NUMBER_EXISTS_NOT_NAME = " Vendor number already exists in the system with another Vendor name";
export const NO_SERIAL_VENDOR = "This Serial does not have vendor";

export const MERGE_VENDORS = "Merge Vendors";
export const IMAGES_SAVED_SUCCESS = "Images saved successfully";
export const FILE_NAME_EXIST = "Few file(s) in the current selection are duplicate and hence ignored";
export const FILE_NOT_READY_TO_DOWNLOAD = "File doesn't exists. Please try after some time";

export const IMAGES_DELETED_SUCCESS = "Images deleted successfully";
export const SAMPLE_TEST_CYCLE_SIZE_LIMIT =
  "Maximum sample cycle is restricted to " + MAX_CYCLE_SIZE + ".";
export const SAMPLE_TEST_CYCLE_DELETE_RESTRICTION =
  "Cycle cannot be deleted as it is not in new state";
export const SAMPLE_GROUP_NAME_LIMIT =
  "Sample group name character limit is restricted to 50.";
export const SAMPLE_GROUP_SIZE_LIMIT =
  "Maximum sample size is restricted to " + SAMPLE_GROUP_SIZE_MAX + ".";
export const SAMPLE_TESTS_SAVED = "Sample type tests saved successfully.";
export const SKU_SAMPLE_SAVED = "Sku sample type(s) saved successfully.";
export const TEST_TYPE_QUESTIONS_SAVED =
  "Test type question(s) saved successfully.";
export const LAB_USERS_SAVED =
  "Lab user(s) saved successfully.";
export const CONTACT_ADMIN = "Please contact your Admin";
export const REQUIRED_FIELD_MESSAGE = "One ​option mandatory";
export const REQUIRED_FIELD_CANNOT_BE_EMPTY = "Mandatory fields missing​";
export const INVALID_DATE_FORMAT = "Invalid Date";
export const UNEXPECTED_MIN_MAX_VALUE_RANGE = "Value out of range";
export const INFINITE_ERROR = "Value cannot be infinite";
export const QUESTION_DOES_NOT_CONTAIN_FORMULA = "Formula unavailable";

export const SELECT_PASS_FAIL_RESULT = "Selected result should be either pass or fail.";

export const INVALID_CYCLE_TEXT = "Enter valid cycle text";
export const CYCLE_ALREADY_FOUND = "Cycle already exists. Please enter a valid cycle text";
export const CYCLE_NO_SAMPLE_FOUND = "Please map at least one sample";

export const SAMPLE_TEST_CYCLE_DELETE_CONFIRM_TITLE = "Delete Sample Test Cycle";
export const SAMPLE_TEST_CYCLE_DELETE_CONFIRM_MESSAGE = "Are you sure you want to delete the selected cycle?";
export const SAMPLE_TEST_CYCLE_SAVED_SUCCESSFULLY = "Test cycle saved successfully.";
export const SAMPLE_TEST_CYCLE_DELETED_SUCCESSFULLY = "Test cycle deleted successfully.";

export const AUTHENTICATING = "Authentication in progress...";
export const ERROR_AUTHENTICATING = "An error occured during authentication, please try again!";
export const INVALID_OPERATION = "Invalid operation";

export const NO_LAB_FOUND = "No Lab found";
export const NO_SKU_FOUND = "No Sku found";
export const NO_VENDOR_FOUND = "No Vendor found";
export const NO_MATCHING_SAMPLE_TYPE_FOUND = "No Matching Sample Type found";
export const NO_USER_FOUND = "No User found";

export const CYCLE_DELETE_CONFIRM_TITLE = "Delete Predefined Cycle";
export const CYCLE_DELETE_CONFIRM_MESSAGE = "Deleting the selected predefined cycle will delete all its question constraints as well. Are you sure want to continue?"

export const FILE_DELETE_CONFIRM_TITLE = "Delete File";
export const FILE_DELETE_CONFIRM_MESSAGE = "Are you sure you want to delete the selected file?"
export const SAMPLEGROUP_DELETE_CONFIRM_TITLE = "Delete SampleGroup";
export const SAMPLEGROUP_DELETE_CONFIRM_MESSAGE = "Are you sure you want to delete the selected Sample Group?";
export const TEST_REQUEST_DELETE_CONFIRM_TITLE = "Delete Test Request";
export const TEST_REQUEST_DELETE_CONFIRM_MESSAGE = "Are you sure you want to delete the selected Test Request?";
export const FILES_SAVED_SUCCESS = "File(s) saved successfully";
export const DROP_ZONE_TEXT = "Drag and drop file(s) here or click to browse file(s) for uploading";
export const MAX_FILE_LIMIT_EXCEED = "Files count should not exceed ";
export const MAX_FILE_SIZE_EXCEED = "File(s) size should not exceed 1GB";
export const NEW_ADDED_FILES_SIZE_EXCEED = "New Added File(s) size should not exceed 1GB";
export const FILE_NOT_UPLOADED = "File(s) cannot be uploaded, please try again.";
export const GREATER_THAN_MIN_DATE = "Selected date should be greater than min date";
export const LESS_THAN_MAX_DATE = "Selected date should be less than max date";
export const INVALID_FILE_NAME = "Enter valid file name.";
export const INVALID_PICTURE = "Picture not found.";
export const CAPTURED_IMAGE_PREVIEW = "Captured image preview";
export const INVALID_IMAGE_FILE = "File should be of image type.";

export const RESULT_SHOULD_BE_PASS_OR_FAIL = "Result should be pass/fail";
export const PARENT_QUESTION_OR_HEADER_MISSING = "Missing question(s)/header(s) referred in the formula";
export const INVALID_SAMPLE_GROUP_STATUS = "Invalid samplegroup status";
export const TEST_REQUEST_CREATED = "Test Request created successfully.";
export const TEST_REQUEST_UPDATED = "Test Request updated successfully.";
export const TEST_REQUEST_ERROR = "Invalid input data provided."
export const SERIAL_DATA_UNAVAILABLE = "Unable to retrieve data corresponding to the serial number. Contact administrator";
export const SERIAL_DATA_PREPOPULATED = "Succcessfully prepopulated data based on serial number";
export const TEST_REQUEST_STATUS_UPDATED = "Test Request(s) pushed to technicians queue.";
export const SPECIFICATION_DOCUMENT_NOTAVAILABLE = "Specification document  is not currently available for this sampletype"
export const TESTPROCEDURE_DOCUMENT_NOTAVAILABLE = "Testing procedure document is not currently available for this sampletype"
export const MANAGER_NOTES_EMPTY_VALUE = "Manager Notes cannot be empty"
export const MANAGER_NOTES_SAVE = "Manager Notes saved successfully"
export const APPROVE_USER_CONFIRMATION_TITLE = "Approve Confirmation";
export const WAIVE_USER_CONFIRMATION_TITLE = "Waive off Confirmation";
export const APPROVE_USER_CONFIRMATION_MESSAGE = "Are you sure you want to approve the failed sample groups?";
export const ALERT_APPROVE_NOTE = "Are you sure you want to approve the failed sample groups without entering manager notes?";
export const WAIVE_USER_CONFIRMATION_MESSAGE = "Are you sure you want to waive off the failed sample groups?";
export const ALERT_WAIVER_NOTE = "Are you sure you want to waive off the failed sample groups without entering manager notes?";
export const MAX_SAMPLE_FAILURE_COUNT = "Max sample failure value range should be between ";
export const MIN_SAMPLE_FAILURE_COUNT = "Min sample success value range should be between ";

export const DATE_VALIDATION = {
  INVALID_FROM_DATE: "Invalid from date",
  INVALID_TO_DATE: "Invalid To date",
  INVALID_DATE_RANGE: "Date range should be within 30 days",
  LESS_TO_DATE: "From date should be less than To date"
};

export const TEST_REQUEST_FORM = {
  INVALID_FORM_VALUES: "Please enter valid values.",
  TYPEOFPRODUCT_NOT_SELECTED: "Select valid type of product.",
  PURPOSE_OF_TEST_NOT_SELECTED: "Select purpose of test.",
  FOLLOWUPDATE_GREATER_THAN_REQUESTEDDATE: "Selected date should be greater than requested date"
};

export const CUSTOM_MESSAGE = "Please try after sometime .If issue still persists kindly contact administrator";
export const BULK_UPLOAD_FILE_LIMIT_EXCEEDS = "Files count should not exceed " + DROP_ZONE_CONSTANTS.MAX_BULK_UPLOAD_LIMIT;
export const FILE_TYPE_NOT_SUPPORT = "File type not supported";
export const TEST_TYPE_MAPPING_REQUIRED = "Atleast one test type mapping is required.";
export const SAMPLE_TYPE_AND_LAB_MAPPED_SUCCESS = "Sample type and lab(s) are mapped successfully.";
export const SAMPLE_TYPE_AND_SKU_MAPPED_SUCCESS = "Sample type and SKU(s) are mapped successfully.";
export const APPROVED_SKU_DELETE_CONFIRM_MESSAGE = "Are you sure you want to delete this Qualified Component Database?";
export const QUALIFIED_COMPONENT_DATABASE_DELETE_CONFIRM_TITLE = "Delete Qualified Component Database";
export const SAVE_QUALIFIED_COMPONENT_DATABASE = "Qualified Component Database saved successfully";
export const UPDATED_QUALIFIED_COMPONENT_DATABASE = "Qualified Component Database updated successfully";
export const DELETE_QUALIFIED_COMPONENT_DATABASE = "Qualified Component Database deleted successfully";
export const HEADER_SAMPLE_TYPE_MAPPED_SUCCESS = "Header and sample type(s) are mapped successfully";
export const LAB_AND_SAMPLE_TYPES_MAPPED_SUCCESS = "Lab and Sample type(s) are mapped successfully.";
export const VENDOR_NO_ALREADY_EXISTS = "Vendor no already exists.";
export const INACTIVE_VENDOR_EXISTS = "Vendor exists with inactive state.";
export const VENDOR_NAME_ALREADY_EXISTS = "Vendor name can't be duplicate more than 3 times.";
export const EXPIRY_UNIT_PERIOD_EMPTY = "Please Update the test expiry details.";
export const REMAINDER_UNIT_PERIOD_EMPTY = "Please Update the expiry reminder details.";
export const EXPIRY_CHANGE_WARNING ="Changing this value will reset the reminders. Are you sure you want to proceed?";
export const SAMPLE_GROUP_BULK_UPLOAD_CTMS_UPLOAD = "Once the file is imported will let you know.";
export const SAMPLE_GROUP_BULK_UPLOAD_SAVED = "Sample group has been uploaded successfully";
export const TEST_TYPE_REVIEW_DATE = "The notification remainder exceed expiry date.";
export const REMINDER_TOOLTIP = "Reminder will be set before the expiry date";
export const REMINDER_SENT = "Reminder has been sent";
export const ARE_YOU_SURE = "Are you sure?";
