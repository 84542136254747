import { GLS_API_URL } from "../../../shared-component/utilities/apiConfig";
const EXPORT_SAMPLE_GROUP = "sample-group/export";
const UPDATE_STATUS = "sample-group/update-status";
const UPDATE_RESULT = "sample-group/update-result";
const SAMPLE_GROUP_API = "sample-group";
const SAMPLE_GROUP_GET_API = "sample-group/get-sample-groups";
const STATUS_API = "status-constant";

const SAMPLE_GROUP_GET_LABS = "sample-group-filter-field/get-labs";
const SAMPLE_GROUP_GET_SKUS = "sample-group-filter-field/get-sku";
const GET_VENDORS = "sample-group-filter-field/get-vendors";
const QuestionConstraintAPI = "question-constraint";
const BulkUpload = "/bulk-upload";
const CTMS = "ctms";
const CTMS_BULK_UPLOAD = "/ctms-bulk-upload";

export const SampleGroupApi = () => {
  return GLS_API_URL + SAMPLE_GROUP_GET_API;
};

export const SampleGroupExportApi = () => {
  return GLS_API_URL + EXPORT_SAMPLE_GROUP;
};

export const SampleGroupResultUpdateApi = () => {
  return GLS_API_URL + UPDATE_RESULT;
};

export const SampleGroupStatusUpdateApi = () => {
  return GLS_API_URL + UPDATE_STATUS;
};

export const SampleGroupDeleteApi = (sampleGroupId) => {
  return GLS_API_URL + SAMPLE_GROUP_API + "?sampleGroupId=" + sampleGroupId;
};

export const fetchAllStatusApi = () => {
  return GLS_API_URL + STATUS_API;
};

export const FilterFieldLabsApi = () => {
  return GLS_API_URL + SAMPLE_GROUP_GET_LABS;
};

export const FilterFieldVendorsApi = () => {
  return GLS_API_URL + GET_VENDORS;
};

export const FilterFieldSkusApi = () => {
  return GLS_API_URL + SAMPLE_GROUP_GET_SKUS;
};

export const SaveSampleGroupUploadUrl = () => {
  return GLS_API_URL + CTMS + CTMS_BULK_UPLOAD;
};
