export const ADD_SAMPLE_TYPE = "Add Sample Type";
export const UPDATE_SAMPLE_TYPE = "Update Sample Type";
export const SAMPLE_TYPE = "Sample Type";
export const ADD_VENDOR = "Add Vendor";
export const EDIT_VENDOR = "Edit Vendor";
export const POUR_DATE = "Pour Date";
export const UPDATE_VENDOR = "Update Vendor";
export const VENDOR = "Vendor";
export const VENDOR_NO = "Vendor No";
export const DOWNLOAD_VENDOR_TEMPLATE =
  "Download sample vendor file upload template";
export const UPLOAD_VENDOR_TEMPLATE = "Upload Vendor file";
export const MERGE_VENDORS = "Merge Vendor(s)";
export const VENDORS = "Vendors";
export const ISMASTER = "Is Master Record";
export const MANDATORY = " *";
export const SAVE = "Save";
export const MERGE = "Merge";
export const SAVE_AS_DRAFT = "Save as draft";
export const SAVE_COMPLETE = "Save & Complete";
export const MANAGER_NOTES = "Manager Notes";
export const SAVE_NEXT = "Save & Next";
export const SAVE_PUSH = "Save & Push";
export const APPLY_ALL = "Apply";
export const RESET_CONSTRAINT = "Reset";
export const NEXT = "Next";
export const UPDATE = "Update";
export const CLOSE = "Close";
export const SUCCESS = "Success";
export const CANCEL = "Cancel";
export const CONTINUE = "Continue";
export const SUBMIT = "Submit";
export const NO_ATTACHMENT_AVAILABLE = "No attachments available";
export const ATTACHMENT = "Attachments";
export const EMPTY_SAMPLE_GROUP_MAPPING =
  "There are no headers associated with this sample type, please map at least one header field.";
export const TEST_QUESTION_BY_SAMPLE = "Test Question Sample";
export const SAMPLE_GROUP_REVIEW = "Sample Group Review";
export const UPDATE_TEST_TYPE = "Update Test Type";
export const ADD_TEST = "Add Test";
export const EXPORT_PDF_GROUPING = "Export Pdf Grouping";
export const UPLOAD_SAMPLEGROUP_FILE = "Upload Sample group file";

export const EQUIPMENT_NAME = "Equipment Name";
export const SELECT_AGGREGATES = "Select Aggregates";
export const EXPIRY_PERIOD = "Expiry Period";

export const EQUIPMENT = "Equipment ";
export const ADD_EQUIPMENT = "Add Equipment ";
export const UPDATE_EQUIPMENT = "Update Equipment ";

export const LAB_EQUIPMENT = "Lab Equipment ";
export const ADD_LAB_EQUIPMENT = "Add Lab Equipment ";
export const UPDATE_LAB_EQUIPMENT = "Update Lab Equipment ";
export const ADD_TEST_TYPE = "Add Test Type";
export const ADD_HEADER_FIELD = "Add Header Field";
export const HEADER_FIELD = "Header Field";
export const CUSTOM_UNIT_TYPE = "Custom Unit Type";
export const INFO_URL = "Additional Info";
export const MIN_VALUE = "Min Value";
export const AVG_MAX_VALUE = "Avg Max Value";
export const AVG_MIN_VALUE = "Avg Min Value";
export const MAX_VALUE = "Max Value";
export const DECIMAL_DIGIT = "Decimal Digits";
export const NOMINAL_VALUE = "Nominal Value";
export const PREDEFINED_DATA_SET = "Predefined Dataset";
export const INVALID_DATE = "Invalid Date";
export const PREDEFINED_OPTIONS = "Predefined Options";
export const LAB_NAME = "Lab Name";
export const UPDATE_HEADER_FIELD = "Update Header Field";
export const TEST = "Test";
export const TESTS = "Tests";
export const TEST_TYPES = "Test Types - Lab Equipment";
export const TEST_STATUS = "Test Status";
export const TEST_RESULTS = "Test Results";
export const TEST_TYPE = "Test Type";
export const TEST_DESC = "Test Description";
export const TEST_TYPE_QUESTION_COUNT = "Linked Questions";
export const ADD_LAB = "Add Lab";
export const SITE_NAME = "Site Name";
export const USERS_COUNT = "Linked Users";
export const UPDATE_LAB = "Update Lab";
export const LABS = "Lab";
export const LAB_MASTER_ID = "Lab Master Id";
export const ADD_QUESTION = "Add Question";
export const UPDATE_QUESTION = "Update Question";
export const QUESTION = "Question";
export const IS_DEFAULT_VALUE = "Is Default Value";
export const HEADER = "Header";
export const MATH = "Math";
export const DESCRIPTION = "Description";
export const SAMPLE_GROUP_SUMMARY = "Sample Groups";
export const SAMPLE_TEST_SUMMARY = "SampleTestSummary";
export const TEST_REQUEST = "Test Requests";
export const TEST_REQUEST_FORM_TITLE = "Test Request Form";
export const IMAGE_UPLOAD_ERROR = "Please upload a file";
export const REMAINDER_NOTES = "Reminder Notes";
export const USER_NAME = "User Name";

export const MATH_FUNCTION = "Math Function";
export const TEST_REQUEST_SUMMARY = "Test Request Summary";
export const CREATE_TEST_REQUEST = "New test request";
export const UPDATE_TEST_REQUEST = "Update test request";
export const DELETE_TEST_REQUEST = "Delete test request";
export const FormFields = {
  On_Behalf_Of_Requesters: "On Behalf Of Requesters",
  Request_Person: "Request Person",
  Request_Date: "Request Date",
  Followup_Date: "Follow Up By",
  Tracking_Number: "Tracking Number",
  Type_Of_Product: "Type of product",
  sample_type: "Sample Type",
  Vendor_Information: "Vendor Information",
  Submitted_Area: "Submitted Area",
  Reason_for_Test: "Reason for test",
  Laboratory_Requested: "Laboratory Requested",
  Product_Type: "Product Type",
  Type_of_Finished_Good: "Type of Finished Good",
  Type_of_request: "Type of request",
  Type_of_Component: "Type of Component",
  Notes: "Notes",
  File_Upload: "File Upload",
  SKU: "SKU",
  SampleCount: "Sample(s) Count",
  PONumber: "Purchase Order",
  Purpose_Of_Test: "Purpose of test",
};

export const ProductTypeKey = {
  Finished_Good: 1,
  Component: 2,
};

export const PriorityKey = {
  Very_Hot: 1,
  Should_do: 2,
  Nice_to_have: 3,
};

export const ProductType = {
  Component: "Component",
  Finished_Good: "Finished Good",
};

export const FormPriority = {
  Very_Hot: "1 - Very Hot",
  Should_do: "2 - Should do",
  Nice_to_have: "3 - Nice to have",
};

export const RESET = "reset";
export const INPUT = "input";
export const CLEAR = "clear";
export const SAMPLE_GROUP_SELECTED = " sample group(s) selected";
export const TEST_REQUEST_SELECTED = " test request(s) selected";
export const VENDOR_SELECTED = " vendors selected";
export const STATUS = "Status";
export const EDIT = "Edit";
export const SAMPLE_TEST_TYPES = "Sample Test Types";
export const SAMPLE_GROUP_TEST_TYPES = "Sample Group Test Types";
export const SAMPLE_GROUP_NAME = "Sample Group Name";
export const SAMPLE_GROUP_ID = "Group ID";
export const PURCHASE_ORDER = "Purchase Order";
export const PURPOSE_OF_TEST = "Purpose of Test";
export const SERIAL_NUMBER = "Serial #";
export const SAMPLE_GROUP_SIZE_LABEL = "Sample Group Size";
export const LINKED_SAMPLE = "Sample Count";
export const LINKED_SAMPLE_GROUP_TEST_TYPES = "Test Types Count";
export const REQUESTED_DATE = "Requested Date";
export const CYCLE_CONFIG = "Cycle Configuration";
export const VENDOR_NAME = "Vendor Name";
export const CREATED_BY = "Created By";
export const INVALID_VENDOR_MSG = "Please enter a valid vendor number and name";
export const ADD = "ADD";
export const ADD_NEW_VENDOR = "Add a new vendor";

export const EMPTY_SELECT = "Select...";

export const DATA_TYPE = "Data Type";
export const INPUT_TYPE = "Input Type";
export const UNIT_TYPE = "Unit Type";
export const IS_REQUIRED = "Required";
export const IS_CONTACT_PERSON = "Contact Person";
export const IS_TECH_USER = "Tech User";
export const INCLUDE_TEST_REQUEST = "Request Form";
export const IS_PDF_REQUIRED = "PDF Required";
export const IS_CONSTRAINT_APPLICABLE = "Constraint Applicable";
export const IS_CALCULATED = "Calculated";

export const DEFAULT_VALUE = "Default Value";
export const VALUE_TYPE = "Value Type";
export const UNIT_OF_MEASUREMENT = "Uom";
export const VALUE = "Value";
export const ADD_HEADER_MAPPING = "Add Header Mapping";
export const HEADER_MAPPING = "Header(s) Count";
export const VENDOR_MAPPING = "Vendor(s) Count";
export const LABS_COUNT = "Lab(s) Count";
export const ADD_VENDOR_MAPPING = "Add Vendor Mapping";
export const SKU_MAPPING = "SKU Mapping";
export const TEST_MAPPING = "Test(s) Count";
export const QUESTION_MAPPING = "Question Mapping";
export const SAMPLE_TYPE_MAPPING = "Sample Type Mapping";
export const AUTO_TEST_REQUEST_MAPPING = "Auto Test Request Mapping";
export const ON_BEHALF_OF_USERS = "On Behalf Of Users";
export const HEADER_FIELDS = "Header Fields";
export const SORTING_NO = "Sorting No";
export const IS_ACTIVE = "Is Active";
export const IS_MASTER_LAB = "Is Master";
export const ACTIVE = "Active";
export const FALSE = "False";
export const TRUE = "True";
export const ADD_SKU = "Add SKU";
export const ADD_AUTO_TEST_REQUEST_MAPPING = "Add Auto Test Request Mapping";
export const SKU = "SKU";
export const SKUS = "SKU(s)";
export const LAB = "LAB";
export const MAX_ALLOWED_SAMPLE_FAILURE = "Max Allowed Sample Failures";
export const MIN_ALLOWED_SAMPLE_SUCCESS = "Min Allowed Sample Success";
export const POURDATE = "Pour Date";
export const SKU_MAINTENANCE = "SKU Maintenance";
export const SKU_DESCRIPTION = "SKU Description";
export const EDIT_SKU = "Edit SKU";
export const EDIT_AUTO_TEST_REQUEST_MAPPING = "Edit Auto Test Request Mapping";
export const SAMPLE_TYPE_USER_NAME = "User name *";

export const YES = "Yes";
export const NO = "No";
export const IN_ACTIVE = "InActive";
export const IS_CYCLE = "isCycle";
export const CYCLE_APPLICABLE = "Cycle Applicable";
export const COLOR_CODE_APPLICABLE = "Is Color Coding Applicable";
export const TEST_CYCLE_CONSTRAINT = "Is Test Cycle Constraint";
export const IS_PREDEFINED_CYCLE = "Is Predefined Cycle";
export const IS_CYCLE_APPLICABLE = "Is Cycle Applicable";
export const RESULT_AUTO_CALCULATED = "Result Auto Calculated";
export const IS_RESULT_AUTO_CALCULATED = "Is Result Auto Calculated";
export const IS_MULTIPLE_SKU_APPLICABLE = "Is Mulitple Sku Applicable";
export const IS_SKU_CONSTRAINT_APPLICABLE = "Is Sku Constraint Applicable";
export const IS_FREEFORM_VENDOR_ENTRY = "Allow freeform Vendor Entry";
export const ALLOW_TEST_REQUEST_CREATION = "Allow Test Request Creation";
export const CYCLES = "Cycles";
export const IS_SET_REMINDER = "Is Set Reminder";

export const CYCLE_CONSTRAINT = "Cycle Constraint";
export const DEFAULT_CONSTRAINT = "Default Constraint";
export const MINMAX_SAMPLE_SUCCESS_FAILURE = "Min/Max Sample Success/Failure";
export const SETUP_MODULE = "Setup";
export const TEST_MODULE = "Test Creation";
export const REVIEW_MODULE = "Review";
export const DASHBOARD = "Dashboard";
export const USER_ROLE_MODULE = "User Role";

export const QUESTION_CONSTRAINT = "Question Constraints";
export const EDIT_CONSTRAINT = "Edit Constraint";
export const DELETE_CONSTRAINT = "Delete Constraint";
export const ADD_CONSTRAINT = "Add Constraint";
export const MIN_DATE = "Min Date";
export const MAX_DATE = "Max Date";

export const VALIDATE = "Validate";
export const CONSTANT = "Constant";
export const ADD_FORMULA = "Add Formula";
export const EDIT_FORMULA = "Edit Formula";
export const FORMULA = "Formula";
export const ADD_TO_FORMULA = "Add to formula";
export const FORMULA_VALIDATION = "Formula validation";
export const SAMPLE_EXPRESSION = "Sample Expression";
export const OUTPUT = "Output";
export const DEFAULT_QUESTION_FORMULA = "Default Question Formula";
export const QUESTION_FORMULA = "Question Formula";
export const UNSELECTED_QUESTIONS = "Unselected Question(s)";
export const SELECTED_QUESTIONS = "Selected Question(s)";

export const UNMAPPED_USERS = "Unmapped User(s)";
export const MAPPED_USERS = "Mapped User(s)";
export const SELECTED_LAB = "Selected Lab";

export const UNSELECTED_TESTS = "Unselected Tests";
export const SELECTED_TESTS = "Selected Tests";

export const UNSELECTED_SAMPLE_TYPE = "Unselected Sample Type";
export const SELECTED_SAMPLE_TYPE = "Selected Sample Type";

export const UNSELECTED_HEADERS = "Unselected Headers";
export const SELECTED_HEADERS = "Selected Headers";

export const SAMPLE_GROUP_CREATION = "New Sample Group";
export const SAMPLE_GROUP_UPDATE = "Update Sample Group";
export const SAMPLE_GROUP_DELETE = "Delete Sample Group";
export const SAMPLE_GROUP_CREATE_NAME = "Enter sample group name";
export const GROUP_BY = "Group By";
export const REPORT_FORMAT = "Report Format";
export const NOTIFICATION_UNIT = "Reminder In";
export const NOTIFICATION_PERIOD = "Reminder Period";

export const BACKDROP_CLICK = "backdropClick";

export const SAMPLE_GROUP_NAME_DISABLED =
  "Sample group name cannot be edited once the test has begun";
export const SAMPLE_GROUP_SIZE = "Enter the number of samples to be tested";
export const SAMPLE_GROUP_SIZE_DISABLED =
  "Selected sample size. (Sample size cannot be edited once the test has begun)";
export const SAMPLE_GROUP_TEST_CONFIGURED =
  "Configure tests for this sample group";
export const SAMPLE_GROUP_TEST_DISABLED =
  "Configured tests for this sample group. (Tests cannot be configured right now)";
export const SAMPLE_GROUP_SKU = "Select SKU associated with this sample group";
export const SAMPLE_GROUP_SKU_TEXT = "Select SKU";
export const CANCEL_DIALOG_TITLE = "Cancel confirmation";
export const CANCEL_DIALOG_TITLE_CONFIRMATION = "Confirmation";
export const CANCEL_DIALOG_MESSAGE = "Are you sure you want to cancel?";
export const SELECTED_SKU = "Selected SKU associated with this sample group";
export const UNSELECTED_SAMPLE_TESTS = "Unselected Sample Tests";
export const SELECTED_SAMPLE_TESTS = "Selected Sample Tests";
export const DELETE_ROW = "Delete Row";

export const SAMPLE_GROUP_LAB_TEXT = "Select Lab";
export const SAMPLE_GROUP_PURPOSE_OF_TEST = "Select purpose of test";
export const SAMPLE_GROUP_VENDOR_TEXT = "Select Vendor";
export const SAMPLE_GROUP_LAB = "Select Lab associated with this sample group";
export const SAMPLE_GROUP_VENDOR =
  "Select vendor associated with this sample group";
export const SAMPLE_GROUP_PURCHASEORDER = "Enter PO number";
export const SAMPLE_GROUP_SERIALNUMBER = "Enter serial number";
export const OPEN_READER_SERIAL_NUMBER =
  "Open barcode reader to scan serial number";

export const QUESTIONS = "Question(s)";
export const CYCLE = "Cycle";
export const SAMPLE = "Sample";
export const SAMPLES = "Samples";
export const VIEW_BY = "View by";
export const SAMPLE_GROUP_NO = "Sample Group No";
export const SAMPLE_GROUP = "Sample Group";
export const SELECTED_SAMPLE_GROUPS = "Selected Sample Groups";
export const SELECTED_VENDORS = "Selected Vendors";
export const SELECTED_TEST_REQUESTS = "Selected Test Requests";
export const HOME = "Home";
export const SAMPLE_TESTING = "Sample Testing";
export const CREATE_TEST = "Create Test";
export const SAMPLE_GROUP_TEST = "Sample Group Test";
export const TEST_QUESTION = "Test Question";
export const RESULT = "Result";
export const IMAGES = "Images";
export const OVERRIDE_VALIDATAION = "Override Validation";
export const HRS = "Hr's-";
export const SAMPLE_GROUP_TYPE = "Sample type selected";

export const TOOLTIP_UNIT = "Unit ";
export const TOOLTIP_VALIDATION = "Validation ";
export const TOOLTIP_MIN = "Min ";
export const TOOLTIP_MAX = "Max ";
export const FIXED_INPUT_TEST_CONFIGURED = "Tests Configured";
export const MAX_ALLOWED_QUESTION_FAIL = "Max Allowed Question Failures";

export const SAMPLE_TITLE = "Samples";
export const SAMPLE_STATUS = "Sample Status";
export const SAMPLE_RESULT = "Sample Result";
export const TEST_LIST = "Test List";
export const FAIL = "Fail";
export const PASS = "Pass";
export const BACK = "Back";
export const NO_RESULTS = "No Results";
export const NO_DATA = "No Data!";
export const NEW = "New";
export const ADD_NEW_ROW = "Add New Row";
export const TEST_PASSED = "Tests Passed";
export const SAMPLE_GROUP_HEADER = "Click here to view Header details";
export const IN_PROGRESS = "In Progress";
export const COMPLETE = "Complete";
export const SKIPPED = "Skipped";
export const APPROVE = "Approve";
export const REJECT = "Reject";
export const PENDING_REVIEW = "Pending Review";
export const NEEDS_FOLLOWUP = "Needs follow-up";
export const APPROVE_SAMPLEGROUP = "Approve Sample Group";
export const REJECT_SAMPLEGROUP = "Reject Sample Group";
export const TEST_CYCLE_CONFIGURATION = "Test Cycle Configuration";
export const SELECT_TEST = "Select Test";
export const TEST_REVIEW = "Test Review";
export const ALL = "All";
export const NONE = "None";
export const NA = "NA";
export const NA_WITHSLASH = "N/A";
export const CYCLE_ZERO = "Cycle 0";
export const MARK_SAMPLE_GROUP_STATUS = "Set sample group status :";
export const SAMPLEGROUP_FILE_COUNT = "SampleGroup FileCount";
export const TEST_COMPLETION_DATE = "Test Completion Date";

export const REQUESTED_BY = "Requested By";
export const APPROVAL_PENDING = "Pending";
export const APPROVAL_LANDING = "Approval Landing";
export const UPLOAD_FILES = "Upload Files";
export const UPLOAD_SINGLE_FILE = "Upload File";
export const DRAG_DROP_CONTENT_SINGLE_FILE =
  "Drag and drop file here or click to browse file for uploading";
export const DRAG_DROP_CONTENT =
  "Drag and drop file(s) here or click to browse file(s) for uploading";
export const TOTAL_COUNT = "Total Files";

export const OTHER_FILES = "Other Files";
export const HYPHEN = " - ";
export const PIPE = " | ";

export const FILES = "File(s)";
export const NO_FILES = "No File(s)";
export const EDIT_HEADER_FIELD = "Edit Header Field";
export const EDIT_LAB = "Edit Lab";
export const EDIT_SAMPLE_TYPE = "Edit Sample Type";
export const EDIT_TEST_TYPE = "Edit Test Type";
export const EDIT_QUESTION = "Edit Question";
export const IS_AGGREGATE_APPLICABLE = "Is Aggregate Applicable";
export const IS_RESULT_SHOW_IN_REPORT = "Show Result In Report";
export const IS_ESTIMATED_TEST_DURATION = "Show Estimated Test Duration";
export const PDF = "pdf";
export const XLSX = "xlsx";
export const DOC = "doc";
export const IS_EXPIRY = "Set test expiration";

export const PAGE_OPTION_TYPE_RESULT = "Results";
export const PAGE_OPTION_TYPE_STATUS = "Status";

export const SELECT_CYCLE = "Select Cycle";
export const CYCLE_TEXT = "Cycle Text";
export const ADD_NEW = "Add New";
export const DELETE = "Delete";
export const ADD_NEW_SELECT_TEXT = "-- Add New --";
export const UNMAPPED_SAMPLES = "Unmapped samples";
export const MAPPED_SAMPLES = "Mapped samples";
export const SELECTED = "Selected";
export const LOGOUT = "Logout";

export const FILTER = "filter";
export const CYCLE_CHANGE = "cycle_change";
export const TEST_CHANGE = "test_change";
export const SAMPLE_CAHNGE = "sample_change";
export const BACK_BUTTON = "back_Button";

export const UPLOADED_DATE = "Uploaded Date";
export const FILE_NAME = "File Name";
export const PENDING = "Pending";
export const MANDATORY_SYMBOL = "*";
export const SHIFT_SELECTED_TEST_DOWN_ORDER =
  "Shift selected test down the order";
export const SHIFT_SELECTED_TEST_UP_ORDER = "Shift selected test up the order";
export const MOVE_SELECTED_LEFT = "Move selected left";
export const MOVE_SELECTED_RIGHT = "Move selected right";

export const APPROVAL_LANDING_EXPORT_FILENAME = "Approvallanding.csv";
export const EXPORT_TO_CSV = "Export as CSV";
export const INFINITE = "Infinity";
export const PO_NUMBER = "PO Number";
export const TEST_DATE = "Test Date";
export const ALL_SELECTED = "All Selected";

export const CALCULATED_VALUES = "CalculatedVaues";
export const SELECT_SAMPLE_TYPE = "Select Sample Type";
export const EXPORT_AS_EXCEL = "Export as Excel";
export const EXPORT_AS_PDF = "Export as PDF";
export const SAMPLE_TYPE_REPORT = "Sample Type Report";
export const OPEN_SAMPLE_TYPE_REPORT = "Open sample type report";
export const FROM_DATE = "From Date";
export const TO_DATE = "To Date";
export const OPEN_FILES = "Open Files";
export const SAMPLE_GROUP_PAGE = "sampleGroupPage";
export const SAVE_AND_OVERRIDE = "Save and Override";
export const FILTER_NAME = "Field Name";
export const SEARCH_TEXT = "Search Text";
export const SAMPLE_NAME = "Sample Name";
export const REVIEW_COMPLETED_TEXT = "Review Completed";
export const COMPLETED = "Completed";
export const COMPLETED_DATE = "Completed Date";

export const REVIEW_STATUS = "status";
export const CAPTURE = "Capture";
export const TAKE_PHOTO = "Take Photo";
export const DOWNLOAD_SPECIFICATION_DOCUMENTS =
  "Download specification documents";
export const ALL_VENDORS = "Show all vendors";
export const DOWNLOAD_TESTPROCEDURE_DOCUMENTS =
  "Download test procedure documents";
export const GENERAL = "General";
export const SPECIFICATION_DOCUMENTS = "Specification document(s)";
export const TECH_TEST_PROC_DOCU = "Test procedure document(s)";
export const FAILED = "Failed";
export const GLS_ADMIN = "GLS Admin";
export const GLS_EXTERNAL_USER_ROLE_NAME = "GLS External User";
export const TEST_LOCKED_MSG = "This test is locked for edit by ";
export const DOWNLOAD_QUESTION_TEMPLATE = "Questions Bulk upload template";
export const UPLOAD_QUESTION_TEMPLATE = "Upload Questions";
export const DOWNLOAD_QUESTION_CONSTRAINT_TEMPLATE =
  "Question Constraints Bulk upload template";
export const UPLOAD_QUESTION_CONSTRAINT_TEMPLATE =
  "Upload Question Constraints";
export const ADD_NEW_REMAINDER = "Add Reminder";

export const FILTER_FIELD = {
  show: "Show",
  hide: "Hide",
  clear_hide: "Clear & Hide",
};

export const DATA_TYPES = {
  object: "object",
  string: "string",
};

export const NOTIFICATION_CONSTANTS = {
  KEY: "timeStamp",
  VALUE: "message",
  HEADING: "Notifications",
  NO_MESSAGE: "No Messages",
  COMPLETED_STATUS: "Completed",
  LOCALSTORAGE_KEY: "notifications",
};

export const TEST_REQUEST_FORM = {
  viewTestRequestForm: "View Test Request Form",
  testRequestForm: "Test Request Form",
};

export const HISTORY_LOGS = {
  statusHistory: "Status History Logs",
};

export const DASHOBOARD_AREA = {
  sampleGroup: "Sample Group",
  reviewLanding: "Review Landing",
  testRequestForm: "Test Request Form",
  Admin: "Admin",
  searchSKU: "Search SKU",
};

export const DASHOBOARD_AREA_MODULE = {
  sampleGroup: "sampleGroup",
  reviewLanding: "reviewLanding",
  testRequestForm: "testRequestForm",
};

export const QR_CODE = {
  documentTitle: {
    sampleGroup: "Sample Group QR Code",
    sample: "Sample QR Code",
  },
  createQRDialogTitle: "Create QR Code",
  scanDialogTitle: "Scan QR Code",
  qrCodeScanner: "QR Code Scanner",
  print: "Print",
  scannedQRCodeSGId: "scannedQRCodeSGId",
  scannedQRCodeSGStatus: "scannedQRCodeSGStatus",
  scannedQRCodeTestSGSumaryId: "scannedQRCodeTestSGSumaryId",
};

export const BARCODE_READER = {
  title: "Scan barcode",
  camera: "Camera",
  retryScan: "Retry Scan",
  select: "Select",
};
export const EQUIPMENT_FORM = {
  equipmentName: "Equipment Name",
  modelNumber: "Model Number",
  testObject: "Test Object",
  isCalibrationRequired: "Is Calibration Required",
  frequency: "Frequency",
};

export const LAB_EQUIPMENT_HEADER = {
  equipmentName: "Equipment Name",
  modelNumber: "Model Number",
  serialNumber: "Serial Number",
  lab: "Lab",
  quantity: "Quantity",
  equipmentNumber: "Equipment Number",
  workCenter: "Work Center",
  assetTag: "Asset Tag",
  lastCalibratedOn: "Last Calibrated On",
  calibrationDueDate: "Calibration Due On",
  contactPerson: "Contact Person",
};

export const CUSTOM = "Custom";
export const OPTIONS = "Options";
export const ACTION = "Action";

export const CONSTRAINT_ACTIONS = {
  resetDefaultConstraint: "ResetDefaultConstraint",
  applyAllCycleConstraint: "ApplyAllCycleConsraint",
};
export const APPLY_TOOLTIP = "Apply to all cycles";
export const SAMPLEGROUP_LAB_EQUIPMENTS = "Sample Groups Lab Equipments";
export const QuestionContrainstExportFile = "QuestionConstraints.csv";
export const UNMAPPED_VENDORS = "Unmapped Vendor(s)";
export const MAPPED_VENDORS = "Mapped Vendor(s)";
export const DOWNLOAD_ALL_FILES = "Download All File(s)";
export const DOWNLOAD = "Download";
export const OPEN_NEW_TAB = "Click to open new tab";
export const IS_SAMPLEGROUP_MANDATORY_REVIEW = "SampleGroup Mandatory Review";

export const UNSELECTED_LABS = "Unselected Labs";
export const SELECTED_LABS = "Selected Labs";
export const UNMAPPED_LABS = "Unmapped Lab(s)";
export const MAPPED_LABS = "Mapped Lab(s)";
export const UNMAPPED_SKUS = "Unmapped SKU(s)";
export const MAPPED_SKUS = "Mapped SKU(s)";
export const QUALIFIED_COMPONENT_DATABASE = "Qualified Component";

export const APPROVED_SKU = {
  id: "Unique ID",
  category: "Category",
  segment: "Segment",
  subSegment: "Sub-Segment",
  description: "Description",
  modelNumber: "Model Number",
  manufacturer: "Manufacturer",
  ashleyPN: "Ashley P/N",
  specification: "Specification",
  year: "Year",
  qualified: "Qualified? (Y/N)",
  glsId: "Gls Id",
  qualifiedComponentDatabase: "Qualified Component",
  addNew: "Add New ",
  update: "Update ",
};
export const IMAGE_PDF_DOWNLOAD_CONFIRMATION =
  "Test has been modified! Please save or click continue to ignore the changes and download the PDF";
export const IMAGE_INCLUDE_PDF = "Include Images in Pdf";
export const LINKED_SAMPLE_TYPES_COUNT = "Sample Type(s)";
export const MANAGER_ROLE = "GLS Manager";
export const CONFIRM = "Confirm";
export const VENDOR_SAMPLE_TYPE_MAP_CONFIRM_MESSAGE =
  "The vendor details already exists, please confirm to map to the selected sample type.";
export const REMAINDER_SENT_ACKNOWLEDGMENT =
  "Reminders will be sent based on expiry date";
export const EXPIRES_IN = "Expires In";
export const LOCATION = "Location";
export const ADDRESS_LINE_1 = "Address Line 1";
export const ADDRESS_LINE_2 = "Address Line 2";
export const WEBSITE = "Website";
export const LAST_NAME = "Last Name";
export const FIRST_NAME = "First Name";
export const LAB_TITLE = "Title";
export const LAB_EMAIL_ADDRESS = "Email Addess";
export const LAB_PHONE = "Phone";
export const LAB_FAX = "Fax";
export const ACCREDITATION_BODY_CERTIFICATION =
  "Accreditation Body Certification";
export const REGISTERED_BODY = "Registered Body";
export const APPROVED_TESTING_SCOPE = "Approved Testing Scope";
